import React from 'react'
import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'

import Page from '~/templates/Page'
import { breakpoints } from '~/utils/styles'

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;

  @media (max-width: ${breakpoints.m}px) {
    display: block;
  }
`

const SmallGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-left: 5rem;
  margin-right: 5rem;

  @media (max-width: ${breakpoints.m}px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    margin-left: 0;
    margin-right: 0;
  }
`

const Item = styled.div`
  flex: 0 0 calc(50% - 2.5rem);
  padding: 2.5rem;
  box-sizing: border-box;

  @media (max-width: ${breakpoints.m}px) {
    padding: 0;
    padding-bottom: 1.45rem;
  }
`

const Text = styled.p`
  line-height: 1.6;
  font-size: 1.2rem;
  text-align: center;
  background-color: white;
  margin: 0px;
  padding: 1.45rem;

  h2 {
    margin-top: 0px;
    margin-bottom: 1.45rem;
  }
`

const Team = () => (
  <Page color="#F2F2F2">
    <div style={{ textAlign: 'center' }}>
      <h1>Team</h1>
    </div>

    <Grid>
      <Item>
        <StaticImage
          src="../images/team/Jens_Portrait2.jpg"
          layout="fullWidth"
          alt="Jens Portrait"
        />
        <Text>
          <h2>Produktentwicklung</h2>
          Ich bin Jens und kümmere mich bei NurEinBerg vor allem um die
          Produktentwicklung und das Konzept. Als Geschäftsführer bin ich dafür
          zuständig, dass vom Marketing bis hin zum Versand an den Kunden alles
          glatt läuft.
          <br />
          <br />
          <i>
            „Man kann alles schaffen im Leben, wenn man bereit ist alles dafür
            zu geben.“
          </i>
        </Text>
      </Item>
      <Item>
        <StaticImage
          src="../images/team/Alex_Portrait2.jpg"
          layout="fullWidth"
          alt="Alex Portrait"
        />
        <Text>
          <h2>Webentwicklung</h2>
          Servus, mein Name ist Alexander und ich habe 2017 zusammen mit Jens
          NurEinBerg gegründet. Ich kümmere mich um den Webshop und die IT,
          sowie die Werbeanzeigen und mediale Gestaltung.
          <br />
          <br />
          <i>
            „If somebody offers you an amazing opportunity but you are not sure
            you can do it, say yes – then learn how to do it later!“ - Richard
            Branson
          </i>
        </Text>
      </Item>
      <Item>
        <StaticImage
          src="../images/team/Kai_Portrait2.jpg"
          layout="fullWidth"
          alt="Kai Portrait"
        />
        <Text>
          <h2>Marketing</h2>
          Ich bin Kai und kümmere mich um das Marketing. Von Flyern bis hin zu
          Kooperationen mit anderen Marken bin ich zuständig. Neben meinem BWL
          Studium bin ich bei NurEinBerg, da ich diese Stadt im Herzen trage.
          <br />
          <br />
          <i>
            „Eine Idee ist nur eine Idee. Wann und wie du sie angehst, bestimmt,
            was daraus wird.“
          </i>
        </Text>
      </Item>
      <Item>
        <StaticImage
          src="../images/team/Hannah_Portrait.jpg"
          layout="fullWidth"
          alt="Anvar Portrait"
        />
        <Text>
          <h2>Kommunikation</h2>
          Hey, ich bin Hannah! Bei NurEinBerg bin ich zuständig für Marketing
          und Kommunikation. Obwohl ich keine „Ursprungs-Nürnbergerin“ sondern
          nur zugezogen bin, habe ich diese Stadt innerhalb kürzester Zeit
          kennen und lieben gelernt.
          <br />
          <br />
          <i>„Quality means doing it right when no one is looking.“</i>
        </Text>
      </Item>
    </Grid>

    <h2 style={{ textAlign: 'center' }}>
      Danke, dass ihr Teil der Geschichte wart und die Marke geprägt habt.
    </h2>
    <SmallGrid>
      <StaticImage
        src="../images/team/Anne_Portrait.jpg"
        layout="fullWidth"
        alt="Anne Portrait"
      />
      <StaticImage
        src="../images/team/Ole_Portrait.jpg"
        layout="fullWidth"
        alt="Ole Portrait"
      />
      <StaticImage
        src="../images/team/Rabije_Portrait.jpg"
        layout="fullWidth"
        alt="Rabije Portrait"
      />
      <StaticImage
        src="../images/team/Anvar_Portrait.jpg"
        layout="fullWidth"
        alt="Anvar Portrait"
      />
    </SmallGrid>
  </Page>
)

export default Team
